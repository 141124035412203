import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import headshot from './assets/bubbles.jpg';
import HideAppBar from './App';
import MiddleDividers from './Divider'
import OppositeContentTimeline from './Timeline';
import reportWebVitals from './reportWebVitals';

class Profile extends React.Component {
  render() {
    return (
      <div className="about">
        <img src={headshot}
            height={240}
            width={240}
            style={{ alignSelf: 'center'}}
            className="headshot"
            />
        <div className="about-text">
          <h1>Hello!</h1>
          <h2>
          My name's Johannes Fung and I'm a computer science junior at UC
          Berkeley expecting to graduate in Spring 2023.
          During my downtime when I'm not working, I try to learn new
          things on the side, programming related or not. Currently into XR
          development using Unity and Unreal Engine 4.
          </h2>
          <h2>
          You can find projects that I'm working on the side right here.
          </h2>
        </div>
      </div>
    );
  }
}

class Header extends React.Component {
  render() {
    return(<div></div>);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <HideAppBar />
    <Profile />
    <MiddleDividers />
    <OppositeContentTimeline />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
