import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';

export default function OppositeContentTimeline() {
  return (
    <React.Fragment>
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">June 2021 - Autodesk</Typography>
            <Typography color="textSecondary">Cloud Platform Engineer Intern</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>
            Built an API from the ground up using GraphQL and Spring Boot.
            Created a basic CI/CD pipeline that built, tested, and deployed a
            dockerized version of said API through Jenkins.
            Connected API to an AWS Neptune database that I wired using
            secret keys.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">June 2020 - Parsons Corporation</Typography>
            <Typography color="textSecondary">Software Engineer Intern</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>First remote internship. Worked as a software engineer intern
                 for the summer quarter. Learnt a lot about DevOps as I was
                 responsible for fixing and adding features to my team's CI/CD
                 pipeline. I then focused on writing Python scripts that made
                 API calls to stream data to a front end application.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">Sept. 2019 - NASA Ames Research Center</Typography>
            <Typography color="textSecondary">Software Engineer Intern</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>Worked at Ames as a software engineer intern for the fall quarter.
                 Work primarily focused on testing software-in-the-loop
                simulations in Java that emulated air traffic control (ATC)</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">June 2019 - Aerospace Corporation</Typography>
            <Typography color="textSecondary">Software Developer Intern</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>First internship as a software developer! Learnt a lot about
                  APIs, and Apache Nifi and a little bit of Kafka. My job
                  was setting up a data pipeline that fed API requests into a
                  Kafka database which would in turn drip feed that information
                  into NASA's World Wind</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
}
